<template>
  <div class="may-ninth d-flex justify-content-end">
    <a href="https://may9.ru/" target="_blank">
      <img alt="День Победы" src="/pic/pobeda.jpg" />
    </a>
  </div>
</template>

<script>
export default {
  name: "MayNinth"
}
</script>

<style lang="scss" scoped>
.may-ninth {
  img {
    max-width: 330px;
  }
}
@media (max-width: 768px){
  .may-ninth {
    img {
      max-width: 100%;
    }
  }
}
</style>